var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.mod
    ? _c(
        "div",
        [
          _c(
            "key-val-line",
            { attrs: { prop: "Name" } },
            [
              _c("form-input", {
                attrs: { spellcheck: "true" },
                model: {
                  value: _vm.mod.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.mod, "title", $$v)
                  },
                  expression: "mod.title",
                },
              }),
            ],
            1
          ),
          _c(
            "key-val-line",
            { attrs: { prop: "Price" } },
            [
              _c("form-input", {
                model: {
                  value: _vm.mod.cost,
                  callback: function ($$v) {
                    _vm.$set(_vm.mod, "cost", $$v)
                  },
                  expression: "mod.cost",
                },
              }),
            ],
            1
          ),
          _c("key-val-line", { attrs: { prop: "Time" } }, [
            _c("div", { staticClass: "col-7 col-md-5" }, [
              _c(
                "div",
                { staticClass: "row no-gutters" },
                [
                  _c("div", { staticClass: "col" }, [_vm._v("Start")]),
                  _c("time-picker", {
                    staticClass: "col",
                    model: {
                      value: _vm.mod.startTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.mod, "startTime", $$v)
                      },
                      expression: "mod.startTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "row no-gutters" },
                [
                  _c("div", { staticClass: "col" }, [_vm._v("End")]),
                  _c("time-picker", {
                    staticClass: "col",
                    model: {
                      value: _vm.mod.endTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.mod, "endTime", $$v)
                      },
                      expression: "mod.endTime",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }