<template>
    <div class="mod-group" :class="{active, 'read-only': readOnly}">
        <div class="mod-group__title-bar" @click="toggleActive(value.optionID)">
            <span class="mod-group__slider" v-if="!readOnly"></span>
            <span class="mod-group__title">{{ value.title }}</span>
            <span v-if="!simple" class="mod-group__expand"></span>
        </div>

        <template v-if="!simple">
            <div class="mod-group__mods" :class="{inactive: !active}" :style="{height: modHeight}">
                <div class="mod-group__mod" v-for="mod in value.children">
                    <span class="mod__title">
                        {{ mod.optionDescription }}
                    </span>
                    <span class="mod__remove" @click="removeMod(mod)"></span>
                </div>
                <a href="#!" class="mod-group__add" @click.prevent="toggleModal" v-if="!readOnly">
                    Add a mod
                </a>
            </div>
            
            <editing-modal v-if="addModModal">
                <editing-modal-header-simple title="Add a Mod" @closeModal="toggleModal" />
                <add-mod
                :products="products"
                @save="addMod"
                />
            </editing-modal>
        </template>
    </div>
</template>
<script>
import editingModal from 'components/editing_modal.vue'
import editingModalHeaderSimple from 'components/editing_modal_header_simple.vue'
import lineItem from 'components/line_item.vue'
import FormInput from 'components/cleverly/FormInput.vue'
import addMod from 'components/AddMod'

export default {
    name: 'ModGroup',
    props: {
        active: {
            type: Boolean,
            default: true,
        },
        value: {
            type: Object,
            required: true,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        products: Array,
        simple: Boolean,
        activeMods: [],
    },
    data: () => ({
        modHeight: null,
        addModModal: false,
        addModActiveIds: [],
        searchQuery: '',
    }),
    computed: {
        filteredMods() {
            if (this.searchQuery === '') {
                return this.mods(true)
            }

            const query = this.searchQuery.toLowerCase()

            return this.mods(true)
                .filter(mod => {
                    const name = mod.optionDescription.toLowerCase()
                    return name.indexOf(query) !== -1
                })
        },
    },
    watch: {
        displayMods(n, o) {
            if (n.length !== o.length) {
                this.reHeight()
            }
        },
    },
    methods: {
        toggleActive() {
            if (!this.simple) {
                this.$emit('toggleActive', this.value.optionID)
            }
        },
        calcHeight() {
            if (!this.simple) {
                this.modHeight = `${this.$el.querySelector('.mod-group__mods').scrollHeight}px`
            }
        },
        reHeight() {
            this.toggleActive(this.value.optionID)
            this.$nextTick(() => {
                this.modHeight = null
                this.toggleActive(this.value.optionID)
            })
        },
        toggleModal() {
            this.searchQuery = ''
            this.addModModal = !this.addModModal
        },
        toggleActiveMod(id) {
            if (this.addModActiveIds.indexOf(id) === -1) {
                this.addModActiveIds.push(id)
            } else {
                this.addModActiveIds.splice(this.addModActiveIds.indexOf(id), 1)
            }
        },
        isActiveMod(id) {
            return this.addModActiveIds.indexOf(id) !== -1
        },
        addMod(mod) {
            const children = this.value.children.concat([mod])
            const newVal = Object.assign(this.value, {children})

            this.$emit('input', newVal)
            this.toggleModal()
            this.reHeight()
        },
        removeMod(mod) {
            this.$emit('removeMod', mod)
            this.reHeight()
        },
        handleSearch(query) {
            this.searchQuery = query
        },
    },
    mounted() {
        this.calcHeight()
    },
    updated() {
        this.calcHeight()
    },
    components: {
        editingModal,
        editingModalHeaderSimple,
        lineItem,
        FormInput,
        addMod,
    },
}
</script>
<style lang="scss" scoped>
    @import '~scss/variables';
    @import '~scss/mixins';
    .mod-group {
        min-width: 240px;
        background-color: $white;
        border: 1px solid $grey_light_1;
        border-radius: 2px;
        overflow-y: hidden;
        margin-right: 128px;

        .modal {
            .editing-modal__inner {
                height: 100%;
                & > .col-12 {
                    height: 100%;
                }
            }
            .line-item {
                background-color: $white;
                padding-left: spacing(sm);
                cursor: pointer;
            }
            .mods {
                &__container {
                    padding-bottom: spacing(xxxl);
                    height: calc(100% - #{spacing(xxxl)});
                    overflow-y: auto;
                    .line-item {
                        &:hover,
                        &--active {
                            background-color: rgba($blue_light_1, 0.2);
                            color: $blue_mid_1;
                        }
                    }
                }
                &__add {
                    position: absolute;
                    bottom: 1px;
                    left: 0;
                    right: 0;
                    text-align: center;
                    cursor: pointer;
                    color: $brand_color;
                    font-size: fz(md);
                    height: spacing(xxxl);
                    &--icon {
                        display: inline-block;
                        background-image: url('~img/mod_add.svg');
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 18px 18px;
                        width: 28px;
                        height: 28px;
                        margin-right: spacing(xxs);
                        vertical-align: middle;
                    }
                }
            }
        }

        &__title-bar {
            display: flex;
            margin-bottom: 0px;
            transition: margin-bottom $transition_short;
        }

        &__title {
            flex: 1 0 auto;
            margin: 10px 0px;
            cursor: pointer;
        }
        &__expand,
        &__slider {
            flex: 0 1 auto;
            cursor: pointer;
        }
        &__slider {
            width: 14px;
            height: 14px;
            background-image: url('~img/slider.svg');
            background-repeat: no-repeat;
            background-size: 14px 14px;
            background-position: center;
            margin: 13px;
        }
        &__expand {
            display: inline-block;
            width: 11px;
            height: 11px;
            background-image: url('~img/square_plus.svg');
            background-repeat: no-repeat;
            background-size: 11px 11px;
            background-position: center;
            margin: 13px;
        }

        &__mods {
            transition: all $transition_short;
            &.inactive {
                height: 0 !important;
                opacity: 0;
                visibility: hidden;
            }
        }
        &__add {
            color: $brand_color;
            border-top: 1px solid $grey_light_1;
            display: block;
            padding: 15px 0px 15px 35px;
            &:hover {
                color: darken($brand_color, 5%);
            }
        }
        &__mod {
            padding-left: 35px;
            border-left: 2px solid transparent;
            line-height: 28px;
            position: relative;
            cursor: pointer;

            &:hover {
                background-color: $grey_light_2;
                border-color: $brand_color;
                .mod__remove {
                    display: inline-block;
                    position: absolute;
                    top: 50%;
                    right: 12px;
                    width: 12px;
                    height: 12px;
                    background-image: url('~img/trash.svg');
                    background-repeat: no-repeat;
                    background-size: 12px 12px;
                    background-position: center;
                    transform: translateY(-50%);
                }
            }
        }
        &.active {
            .mod-group__title-bar {
                margin-bottom: 5px;
            }
            .mod-group__expand {
                background-image: url('~img/square_minus.svg');
            }
        }
        &.read-only {
            .mod-group {
                &__title {
                    margin-left: 13px;
                }
                &__title-bar {
                    border-bottom: 1px solid $grey_light_1;
                }
                &__mod {
                    &:hover {
                        background-color: inherit;
                        border-color: transparent;
                        .mod__remove {
                            display: none;
                        }
                    }
                }
            }
        }
    }
</style>
