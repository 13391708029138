<template>
    <div class="add-mod">
        <div 
            v-if="currentView === 'add-new'" 
            class="row no-gutters"
        >
            <context-title 
                :passedActions="titleBarActions" 
                class="col-12" 
                title="" 
            />
            <div class="col-12">
                <mod-form 
                    class="row" 
                    @formChange="setMod" 
                />
            </div>
        </div>

        <div 
            v-else-if="currentView === 'add-database' && mods.length > 0" 
            class="row no-gutters"
        >
            <database-picker
                :activeItems="activeItems"
                :items="mods"
                :struct="databaseStruct"
                :searchProps="['pos_modifierID', 'pos_modifierName']"
                class="search-list col-12"
                @selected="onDatabaseItemClick"
            >
                <div 
                    slot="header" 
                    class="title-bar row no-gutters justify-content-end"
                >
                    <context-title 
                        :passedActions="databaseTitleBarActions" 
                        class="col" 
                        title="" 
                    />
                </div>
                <div
                    v-if="this.pos == 'omnivore'"
                    class="d-flex"
                    slot="additional-checkbox">
                    <div>Show Mods Only</div>
                    <toggle
                        v-model="showModsOnly" 
                    />
                </div>
            </database-picker>
        </div>
        <div
            v-else
        >
            <p style="text-align: center;">It doesn't appear that this store's POS inventory has been synced.  Please select refresh POS menu in settings and return here.</p>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { toggleItem, removeDups } from "helpers";
import { removeSpecialChars, capitalize } from "helpers/str";
import SlabButton from "components/slab_button.vue";
import ContextTitle from "components/context_title.vue";
import ModForm from "components/mod_form.vue";
import DatabasePicker from "components/DatabasePicker";
import toggle from 'components/cleverly/Toggle'

export default {
    name: "AddMod",
    components: {
        SlabButton,
        ContextTitle,
        ModForm,
        DatabasePicker,
        toggle,
    },
    props: {
        products: {
            type: Array,
            default: () => []
        },
        integrated: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            currentView: "",
            activeItems: [],
            titleBarActions: [
                {
                    type: "proceed",
                    display: "Save",
                    run: () => {
                        this.$emit("save", this.mod);
                    }
                }
            ],
            databaseStruct: {
                title: {
                    value: item => item.pos_modifierName
                },
                posID: {
                    display: "POS ID",
                    value: item => item.pos_modifierID
                },
                grabID: {
                    display: "Grab ID",
                    value: item => item.id
                },
                price: {
                    display: "Price",
                    value: item => item.cost || "0.00"
                },
                path: {
                    display: "Path",
                    value: item => item.pos_productCategory || "N/A"
                }
            },
            showModsOnly: true,
        };
    },
    computed: {
        ...mapGetters([
            "posGroups",
            "posProducts",
            "pos",
        ]),

        databaseTitleBarActions() {
            const save = {
                type: "proceed",
                display: "Add",
                run: () => {
                    this.saveDatabaseItems();
                }
            };

            if (!this.activeItems.length) {
                return [];
            }

            return [save];
        },

        mods() {
            if (this.pos == 'vvn') {
                const items = this.posProducts.reduce((sum, product) => {
                    return sum.concat(product.pos_productSizes.map(size => {
                        return {
                            ...size,
                            id: size.pos_sizeID,
                            pos_productCategory: product.pos_productCategory,
                            pos_modifierID: size.pos_sizeID,
                            pos_modifierName: capitalize(removeSpecialChars(`${product.pos_productName} - ${size.pos_sizeName == 'Unit' ? '' : size.pos_sizeName}`).trim())
                        }
                    }))
                }, [])

                return removeDups(items, "id");

            } else if(this.pos == 'omnivore') {

                var items = []

                if (!this.showModsOnly) {
                    items = items.concat(this.posProducts.reduce((sum, product) => {
                        return sum.concat(product.pos_productSizes.map(size => {
                            return {
                                ...size,
                                id: size.pos_sizeID,
                                pos_productCategory: product.pos_productCategory,
                                pos_modifierID: size.pos_sizeID,
                                pos_modifierName: capitalize(removeSpecialChars(`${product.pos_productName} - ${size.pos_sizeName == 'Unit' ? '' : size.pos_sizeName}`).trim())
                            }
                        }))
                    }, []))
                }

                items = items.concat(this.posGroups.reduce((sum, group) => {
                    return sum.concat(
                        group.pos_modifiers.map(el => ({
                            ...el,
                            id: el.pos_modifierID,
                            pos_modifierName: capitalize(
                                removeSpecialChars(el.pos_modifierName).trim()
                            )
                        }))
                    );
                }, []))
                return removeDups(items, "id");

            } else {
                const items = this.posGroups.reduce((sum, group) => {
                    return sum.concat(
                        group.pos_modifiers.map(el => ({
                            ...el,
                            id: el.pos_modifierID,
                            pos_modifierName: capitalize(
                                removeSpecialChars(el.pos_modifierName).trim()
                            )
                        }))
                    );
                }, [])

                return removeDups(items, "id");
            }
        },
    },

    // Methods

    methods: {
        switchView(view) {
            this.currentView = view;
        },

        setMod(mod) {
            this.mod = mod;
        },

        onDatabaseItemClick(item) {
            this.activeItems = toggleItem(this.activeItems, item.id);
        },

        saveDatabaseItems() {
            const payload = this.activeItems
                .map(el => {
                    return this.mods.find(mod => mod.id === el);
                })
                .filter(el => el);

            this.$emit("savePosItems", payload);
        }
    },
  
    created() {
        if (this.integrated) {
            this.currentView = 'add-database'
        } else {
            this.currentView = 'add-new'
        }
    },

    // Components


};
</script>

<style lang="scss" scoped>
@import "~scss/mixins";
.add-mod {
  padding: spacing(sm) 0;
}

.buttons {
  padding: 0 spacing(sm);
}

.search-list {
  height: 500px;
}

.title-bar {
  .line-item:before {
    height: 0;
  }
}
</style>
