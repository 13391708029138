<template>
    <div class="file-upload">
        <template v-if="url">
            <form-input style="display: inline-block;" /> <input type="button" :value="label" />
        </template>
        <template v-else>
            <label :for="id">
                {{label}}
                <input type="file" :id="id" @change="onupload" accept="image/png, image/jpeg"/>
            </label>
        </template>
    </div>
</template>
<script>
import FormInput from './FormInput'
export default {
    name: 'FileUpload',
    data: () => ({
        id: `file-upload-${Math.random().toString(36).substring(7)}`, // TODO label mismatches input id, make match
    }),
    props: {
        label: {
            type: String,
            required: true,
        },
        url: {
            type: Boolean,
            default: false,
        },
        onupload: {
            type: Function,
        },
    },
    components: {
        FormInput,
    },
}
</script>
<style lang="scss" scoped>
    @import '~scss/variables';
    @import '~scss/mixins';
    .file-upload {
        label,
        input[type="button"] {
            @include hoverable(1, 2);
            @include roboto;
            background-color: $grey_light_3;
            background-image: linear-gradient(to bottom, rgba($black, 0), rgba($black, 0.1));
            width: auto;
            text-align: center;
            display: inline-block;
            padding: 5px 15px;
            border-radius: 2px;
            border: 1px solid rgba($black, 0.11);
            cursor: pointer;
        }
        input[type="file"] {
            display: none;
        }
    }
</style>
