var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "mod-group",
      class: { active: _vm.active, "read-only": _vm.readOnly },
    },
    [
      _c(
        "div",
        {
          staticClass: "mod-group__title-bar",
          on: {
            click: function ($event) {
              return _vm.toggleActive(_vm.value.optionID)
            },
          },
        },
        [
          !_vm.readOnly
            ? _c("span", { staticClass: "mod-group__slider" })
            : _vm._e(),
          _c("span", { staticClass: "mod-group__title" }, [
            _vm._v(_vm._s(_vm.value.title)),
          ]),
          !_vm.simple
            ? _c("span", { staticClass: "mod-group__expand" })
            : _vm._e(),
        ]
      ),
      !_vm.simple
        ? [
            _c(
              "div",
              {
                staticClass: "mod-group__mods",
                class: { inactive: !_vm.active },
                style: { height: _vm.modHeight },
              },
              [
                _vm._l(_vm.value.children, function (mod) {
                  return _c("div", { staticClass: "mod-group__mod" }, [
                    _c("span", { staticClass: "mod__title" }, [
                      _vm._v(" " + _vm._s(mod.optionDescription) + " "),
                    ]),
                    _c("span", {
                      staticClass: "mod__remove",
                      on: {
                        click: function ($event) {
                          return _vm.removeMod(mod)
                        },
                      },
                    }),
                  ])
                }),
                !_vm.readOnly
                  ? _c(
                      "a",
                      {
                        staticClass: "mod-group__add",
                        attrs: { href: "#!" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.toggleModal.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v(" Add a mod ")]
                    )
                  : _vm._e(),
              ],
              2
            ),
            _vm.addModModal
              ? _c(
                  "editing-modal",
                  [
                    _c("editing-modal-header-simple", {
                      attrs: { title: "Add a Mod" },
                      on: { closeModal: _vm.toggleModal },
                    }),
                    _c("add-mod", {
                      attrs: { products: _vm.products },
                      on: { save: _vm.addMod },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }