<template>
    <div class="tabs">
        <tab-bar :items="tabs" :small="true" :action="switchTab" v-if="tabs.length" :starting-tab="startingTab" />
        <div class="tabs__container">
            <slot name="tab"></slot>
        </div>
    </div>
</template>
<script>
import tabBar from '../tab_bar.vue'
export default {
    name: 'Tabs',
    data: () => ({
        tabs: [],
        tabMap: {},
    }),
    props: {
        startingTab: {
            type: [String, Number],
            default: 0,
        },
    },
    methods: {
        switchTab(tab) {
            this.switchTabPanel(tab.index)
        },
        switchTabPanel(index) {
            let tabs = this.$slots.tab
            let nt = tabs[index].elm
            tabs.forEach(tab => {
                tab.elm.className = tab.elm.className.replace('active', '').replace('  ', ' ')
            })
            nt.className = `${nt.className} active`
        },
    },
    mounted() {
        let tabSlots = this.$slots.tab
        this.tabs = tabSlots.map((tab, i) => {
            let tabData = tab.elm.dataset
            return {
                id: tabData.id,
                display: tabData.display,
                index: i,
            }
        })
        tabSlots.forEach(tab => {
            tab.elm.className = `${tab.elm.className} tabs__panel`
        })
        this.switchTabPanel(this.startingTab)
    },
    components: {
        tabBar,
    },
}
</script>
<style lang="scss" scoped>
    @import '~scss/variables';
    @import '~scss/mixins';
    .tabs {
        &__panel {
            display: none;
            transition: all 250ms;
            &.active {
                display: block;
            }
        }
    }
</style>
