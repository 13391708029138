<template>
    <div class="image-upload">
        <tabs>
            <div 
                slot="tab" 
                :data-id="0" 
                data-display="Upload image">
                <line-item class="row no-gutters height-xs--12 align-items-center">
                    <file-upload 
                        :onupload="uploadImage" 
                        label="Upload images" 
                        class="col-12" />
                </line-item>
            </div>
            <div 
                slot="tab" 
                :data-id="1" 
                data-display="Paste image URL">
                <line-item class="row no-gutters height-xs--12 align-items-center">
                    <file-upload 
                        :url="true" 
                        label="Upload" 
                        class="col-12" />
                </line-item>
            </div>
        </tabs>
        <div 
            v-if="images.length" 
            class="image-upload__images block-xs--md">
            <image-block :images="images" />
        </div>
    </div>
</template>

<script>
import Tabs from 'components/cleverly/Tabs'
import FileUpload from 'components/cleverly/FileUpload'
import LineItem from 'components/line_item'
import ImageBlock from 'components/image_block'

export default {
    name: 'ImageUpload',
    components: {
        Tabs,
        FileUpload,
        LineItem,
        ImageBlock,
    },
    props: {
        images: {
            type: Array,
            required: true,
        },
    },
    methods: {
        uploadImage(e) {
            if (e.target && e.target.files) {
                const reader = new FileReader()
                const file = e.target.files[0]
                const fileName = file.name

                reader.readAsDataURL(file)

                reader.addEventListener('load', () => {
                    const imageBase64 = reader.result

                    this.$emit('upload', {imageBase64, fileName})
                })
            }
        },
    },
}
</script>
