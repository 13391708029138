<template>
    <div v-if="mod">
        <!--         <context-title :title="pageTitle" :passedActions="titleBarActions" />
 -->
        <!-- <key-val-line prop="Enabled">
            <toggle v-model="mod.enabled" :slider="true">
        </key-val-line> -->

        <key-val-line prop="Name">
            <form-input 
                v-model="mod.title" 
                spellcheck="true" />
        </key-val-line>

        <key-val-line prop="Price">
            <form-input v-model="mod.cost" />
        </key-val-line>

        <!-- <key-val-line prop="Item ID">
            <form-input v-model="mod.sku" />
        </key-val-line> -->

        <!-- <key-val-line prop="Path">
            <form-input v-model="mod.path">
        </key-val-line> -->

        <!-- <key-val-line prop="Tax (Default)">
            <div class="col-12">
                <toggle v-model="mod.defaultTax" label="Tax (Default)" class="d-inline-block block-xs--md block-xs--xs-right" />Default ({{ mod.taxRate | parseTaxRate}} %)
                <br />
                <span :class="{label: true, disabled: mod.defaultTax}">
                    Tax&nbsp;(Custom)
                </span>
                <div class="group-inputs" v-if="mod.taxes">
                    <template v-for="tax in mod.taxes">
                        <form-input :value="tax.Amount | parseTaxRate" @input="tax.Amount = arguments[0] / 100" :disabled="mod.defaultTax" type="number" class="d-inline-block block-xs--xs-right block-xs--xs-bottom sub-label" :label="tax.Description" />%<br />
                    </template>
                </div>
            </div>
        </key-val-line> -->

        <!-- <key-val-line prop="Mod Variations">
            Yield variation list
        </key-val-line> -->

        <!-- <key-val-line prop="Platform">
            <div class="col-7 col-md-5">
                <div class="row no-gutters">
                    <div class="col">In-store only</div>
                    <toggle class="col" v-model="mod.storeOnly" />
                </div>

                <div class="row no-gutters" v-for="platform in mod.platforms">
                    <div class="col">{{ platform.display }}</div>
                    <toggle class="col" v-model="platform.active" :disabled="mod.storeOnly" />
                </div>
            </div>
        </key-val-line> -->

        <!-- <key-val-line prop="Options">
            <div class="col-7 col-md-5">
                <div class="row no-gutters" v-for="option in mod.options">
                    <div class="col">{{ option.display }}</div>
                    <toggle class="col" v-model="option.active" />
                </div>
            </div>
        </key-val-line> -->

        <key-val-line prop="Time">
            <div class="col-7 col-md-5">
                <div class="row no-gutters">
                    <div class="col">Start</div>
                    <time-picker 
                        v-model="mod.startTime" 
                        class="col" />
                </div>

                <div class="row no-gutters">
                    <div class="col">End</div>
                    <time-picker 
                        v-model="mod.endTime" 
                        class="col" />
                </div>
            </div>
        </key-val-line>

        <!-- <key-val-line prop="Attributes">
            <div class="col-7 col-md-5">
                <div class="row no-gutters" v-for="attribute in mod.attributes">
                    <div class="col">{{ attribute.display }}</div>
                    <toggle class="col" v-model="attribute.active" />
                </div>
            </div>
        </key-val-line> -->

        <!-- <key-val-line prop="Images">
            <image-upload v-model="mod.images" />
        </key-val-line> -->

        <!-- <line-item v-if="mod.id" class="col-12 height-xs--12">
            <div class="row no-gutters height-xs--inherit align-items-center">
                <text-button
                class="block-xs--sm-left"
                type="delete"
                @click.native="deleteModalActive = true">Delete mod</text-button>
            </div>
        </line-item> -->

        <!--         <editing-modal v-if="deleteModalActive" size="sm">
            <alert-dialog
            title="Delete Mod?"
            :onExit="() => deleteModalActive = false"
            :actions="deleteActions"
            >
                This will delete {{ mod.title }}. Are you sure? You can also disable it instead.
            </alert-dialog>
        </editing-modal>
 -->
        <!-- <unload-confirm
        :onExit="handleLeaveModalExit"
        :leaveAction="leaveConfirmationAction"
        :actions="leaveActions" /> -->
    </div>
</template>

<script>
import { parseTaxRate, spreadItems } from 'helpers'
import contextTitle from 'components/context_title.vue'
import keyValLine from 'components/key_val_line.vue'
import modGroup from 'components/mods/mod_group.vue'
import toggle from 'components/cleverly/Toggle.vue'
import formInput from 'components/cleverly/FormInput.vue'
import imageUpload from 'components/image_upload.vue'
import lineItem from 'components/line_item.vue'
import textButton from 'components/text_button.vue'
import editingModal from 'components/editing_modal.vue'
import alertDialog from 'components/alert_dialog.vue'
import TimePicker from 'components/TimePicker'

export default {
    name: 'ModForm',
    filters: {
        parseTaxRate,
        spreadItems,
    },
    components: {
        contextTitle,
        keyValLine,
        modGroup,
        toggle,
        formInput,
        imageUpload,
        lineItem,
        textButton,
        editingModal,
        alertDialog,
        TimePicker,
    },
    data() {
        return {
            mod: {
                id: '',
                display: '1',
                order: '1',
                cost: 0,
                available: true,
                inventoryItemID_Anchor: '',
                title: '',
                startTime: '1/1/1990 12:00 am',
                endTime: '1/1/1990 11:59 pm',
                groupName: '',
                selected: false,
            },
        }
    },
    watch: {
        mod: {
            handler() {
                this.$emit('formChange', this.mod)
            },
            deep: true,
        },
    },
}
</script>
