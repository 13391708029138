var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "image-upload" },
    [
      _c("tabs", [
        _c(
          "div",
          {
            attrs: {
              slot: "tab",
              "data-id": 0,
              "data-display": "Upload image",
            },
            slot: "tab",
          },
          [
            _c(
              "line-item",
              {
                staticClass: "row no-gutters height-xs--12 align-items-center",
              },
              [
                _c("file-upload", {
                  staticClass: "col-12",
                  attrs: { onupload: _vm.uploadImage, label: "Upload images" },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            attrs: {
              slot: "tab",
              "data-id": 1,
              "data-display": "Paste image URL",
            },
            slot: "tab",
          },
          [
            _c(
              "line-item",
              {
                staticClass: "row no-gutters height-xs--12 align-items-center",
              },
              [
                _c("file-upload", {
                  staticClass: "col-12",
                  attrs: { url: true, label: "Upload" },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm.images.length
        ? _c(
            "div",
            { staticClass: "image-upload__images block-xs--md" },
            [_c("image-block", { attrs: { images: _vm.images } })],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }