<template>
    <div class="container">
        <div
            v-if="search"
            class="product-search__container row no-gutters align-items-center"
        >
            <div class="col-4 offset-1">
                <search-bar 
                    :searchAction="onSearch"
                    :bound="true"
                    placeholder="Search"
                />

            </div>

            <div class="d-flex exact-container">
                <div>Exact Search</div>
                <toggle
                    v-model="exactSearch" 
                />
            </div>
            <div class="checkbox-container">
                <slot name="additional-checkbox"/>
            </div>

            <div class="col">
                <slot name="header"/>
            </div>
        </div>

        <iexplorer
            v-if="filteredItems.length"
            ref="list"
            :structHeader="true"
            :struct="struct"
            :children="children"
            :activeItems="activeItems"
            class="list"
        />
    </div>
</template>

<script>
import searchBar from '../components/search_bar.vue'
import iexplorer from '../components/explorer/iexplorer.vue'
import Fuse from 'fuse.js'
import toggle from '../components/cleverly/Toggle.vue'
import KeyValLine from 'components/key_val_line'

export default {
    name: 'DatabasePicker',
    components: {
        searchBar,
        iexplorer,
        toggle,
        KeyValLine
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        struct: {
            type: Object,
            required: true,
        },
        search: {
            type: Boolean,
            default: true,
        },
        searchProps: {
            type: Array,
            default: () => ['inventoryItemName'],
        },
        activeItems: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            searchQuery: '',
            exactSearch: false,
        }
    },
    computed: {
        fuse() {
            const options = {
                shouldSort: true,
                threshold: 0.6,
                location: 0,
                distance: 100,
                maxPatternLength: 32,
                minMatchCharLength: 1,
                keys: this.searchProps
            };
            return new Fuse(this.items, options); // "list" is the item array
        },
        children() {
            const ret = this.filteredItems.map(this.assignHandlers)

            return ret
        },
        filteredItems() {
            if (this.searchQuery === '') return this.items

            if (this.exactSearch) {
                return this.items.filter(el => {
                    var match = false
                    for (let prop of this.searchProps) {
                        match = match | el[prop].toLowerCase().indexOf(this.searchQuery.toLowerCase()) != -1
                    }
                    return match
                })
            } else {
                return this.fuse.search(this.searchQuery)
            }
            // const query = this.searchQuery.toLowerCase()

        }
    },
    methods: {
        assignHandlers(mod) {
            mod.onClick = this.handleItemClick

            return mod
        },
        handleItemClick(item) {
            this.$emit('selected', item)
        },
        onSearch(query) {
            this.searchQuery = query

            if (this.$refs.list) {
                this.$refs.list.$el.scrollTop = 0
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import '~scss/mixins';

.container {
    height: 100%;
}

.product-search {
    &__container {
        height: height(12);
    }
}

.list {
    max-height: calc(100% - 72px);
    overflow: auto;
}

.toggle {
    margin-left: 10px;
}

.exact-container {
    margin-left: 10px;
}

.checkbox-container {
    margin-left: 10px;
}
</style>
