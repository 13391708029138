var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tabs" },
    [
      _vm.tabs.length
        ? _c("tab-bar", {
            attrs: {
              items: _vm.tabs,
              small: true,
              action: _vm.switchTab,
              "starting-tab": _vm.startingTab,
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "tabs__container" }, [_vm._t("tab")], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }