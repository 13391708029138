var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _vm.search
        ? _c(
            "div",
            {
              staticClass:
                "product-search__container row no-gutters align-items-center",
            },
            [
              _c(
                "div",
                { staticClass: "col-4 offset-1" },
                [
                  _c("search-bar", {
                    attrs: {
                      searchAction: _vm.onSearch,
                      bound: true,
                      placeholder: "Search",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex exact-container" },
                [
                  _c("div", [_vm._v("Exact Search")]),
                  _c("toggle", {
                    model: {
                      value: _vm.exactSearch,
                      callback: function ($$v) {
                        _vm.exactSearch = $$v
                      },
                      expression: "exactSearch",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "checkbox-container" },
                [_vm._t("additional-checkbox")],
                2
              ),
              _c("div", { staticClass: "col" }, [_vm._t("header")], 2),
            ]
          )
        : _vm._e(),
      _vm.filteredItems.length
        ? _c("iexplorer", {
            ref: "list",
            staticClass: "list",
            attrs: {
              structHeader: true,
              struct: _vm.struct,
              children: _vm.children,
              activeItems: _vm.activeItems,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }