var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "file-upload" },
    [
      _vm.url
        ? [
            _c("form-input", { staticStyle: { display: "inline-block" } }),
            _vm._v(" "),
            _c("input", { attrs: { type: "button", value: _vm.label } }),
          ]
        : [
            _c("label", { attrs: { for: _vm.id } }, [
              _vm._v(" " + _vm._s(_vm.label) + " "),
              _c("input", {
                attrs: {
                  type: "file",
                  id: _vm.id,
                  accept: "image/png, image/jpeg",
                },
                on: { change: _vm.onupload },
              }),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }