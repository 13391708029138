var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "add-mod" }, [
    _vm.currentView === "add-new"
      ? _c(
          "div",
          { staticClass: "row no-gutters" },
          [
            _c("context-title", {
              staticClass: "col-12",
              attrs: { passedActions: _vm.titleBarActions, title: "" },
            }),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("mod-form", {
                  staticClass: "row",
                  on: { formChange: _vm.setMod },
                }),
              ],
              1
            ),
          ],
          1
        )
      : _vm.currentView === "add-database" && _vm.mods.length > 0
      ? _c(
          "div",
          { staticClass: "row no-gutters" },
          [
            _c(
              "database-picker",
              {
                staticClass: "search-list col-12",
                attrs: {
                  activeItems: _vm.activeItems,
                  items: _vm.mods,
                  struct: _vm.databaseStruct,
                  searchProps: ["pos_modifierID", "pos_modifierName"],
                },
                on: { selected: _vm.onDatabaseItemClick },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "title-bar row no-gutters justify-content-end",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c("context-title", {
                      staticClass: "col",
                      attrs: {
                        passedActions: _vm.databaseTitleBarActions,
                        title: "",
                      },
                    }),
                  ],
                  1
                ),
                this.pos == "omnivore"
                  ? _c(
                      "div",
                      {
                        staticClass: "d-flex",
                        attrs: { slot: "additional-checkbox" },
                        slot: "additional-checkbox",
                      },
                      [
                        _c("div", [_vm._v("Show Mods Only")]),
                        _c("toggle", {
                          model: {
                            value: _vm.showModsOnly,
                            callback: function ($$v) {
                              _vm.showModsOnly = $$v
                            },
                            expression: "showModsOnly",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ],
          1
        )
      : _c("div", [
          _c("p", { staticStyle: { "text-align": "center" } }, [
            _vm._v(
              "It doesn't appear that this store's POS inventory has been synced. Please select refresh POS menu in settings and return here."
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }